@font-face {
  font-family: "DMSans";
  src: url("../public/DMSans_36pt-Regular.woff");
}

div,
input {
  box-sizing: border-box;
}

a {
  color: #091f25;
}

body {
  margin: 0;
  font-family: "DMSans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff0da;
  color: #277888;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
