.App {
  text-align: left;
}

.App-header {
  background-color: #fef3df;
  min-height: 100vh;
  max-width: 1200px;
  min-width: 100%;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  overflow: hidden;
  background-image: url("../public/waill-wide.png");
  background-size: cover;
  background-position: center;
}

.App-header-text {
  color: #72cad8;
  margin: 0px;
  margin-bottom: 10px;
}

.App-header-p {
  color: #72cad8;
  font-size: 24px;
  margin: 0px;
  margin-bottom: 30px;
}

.primary-button {
  border: none;
  padding: 10px 20px 10px 20px;
  background-color: #277888;
  border-radius: 10px;
  color: #eafff3;
  transition: background-color 0.05s linear;
  cursor: pointer;
  font-family: "DMSans";
  text-decoration: none;
  font-size: 18px;
}
.primary-button:hover {
  background-color: #48b2cf;
}
.primary-button:disabled {
  background-color: #6ba4b4;
  cursor: unset;
}

.primary-button-small {
  border: none;
  padding: 7px 17px 7px 17px;
  background-color: #277888;
  border-radius: 10px;
  color: #eafff3;
  transition: background-color 0.05s linear;
  cursor: pointer;
  font-family: "DMSans";
  text-decoration: none;
  font-size: 15px;
}
.primary-button-small:hover {
  background-color: #48b2cf;
}
.primary-button-small:disabled {
  background-color: #6ba4b4;
  cursor: unset;
}

.secondary-button {
  padding: 8px 18px 8px 18px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  color: #277888;
  transition: background-color 0.05s linear;
  transition: border 0.05s linear;
  cursor: pointer;
  border: 2px solid #277888;
  font-family: "DMSans";
  text-decoration: none;
  font-size: 18px;
}
.secondary-button:hover {
  background-color: #eafff3;
  border: 2px solid #eafff3;
}
.secondary-button:disabled {
  background-color: #a2aca6;
  cursor: unset;
}

.secondary-button-small {
  padding: 5px 15px 5px 15px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  color: #277888;
  transition: background-color 0.05s linear;
  transition: border 0.05s linear;
  cursor: pointer;
  border: 2px solid #277888;
  font-family: "DMSans";
  text-decoration: none;
  font-size: 15px;
}
.secondary-button-small:hover {
  background-color: #eafff3;
  border: 2px solid #eafff3;
}
.secondary-button-small:disabled {
  background-color: #d6fff5;
  border: 2px solid #d6fff5;
  cursor: unset;
}

.submit-button {
  padding: 8px 18px 8px 18px;
  background-color: #eafff3;
  border-radius: 10px;
  color: #277888;
  transition: background-color 0.05s linear;
  transition: border 0.05s linear;
  cursor: pointer;
  border: 2px solid #97d3df;
  font-family: "DMSans";
  text-decoration: none;
  font-size: 18px;
}
.submit-button:hover {
  background-color: #eafff3;
  border: 2px solid #eafff3;
}
.submit-button:disabled {
  background-color: #e2f7ef;
  cursor: unset;
}

.navigation-button {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  padding: 10px 20px 10px 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  color: #277888;
  transition: background-color 0.1s linear;
  cursor: pointer;
  border: none;
  font-family: "DMSans";
  text-decoration: none;
  font-size: 18px;
}
.navigation-button:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
.navigation-button:disabled {
  background-color: rgba(0, 0, 0, 0.02);
  cursor: unset;
}

.highlighted-text {
  color: #277888;
}

.profile-button {
  height: 60px;
  width: 100%;
  border: none;
  font-family: "DMSans";
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 10px;
  cursor: pointer;
  background-color: #eafff3;
  color: #277888;
  transition: all 0.1s ease;
}

.profile-button:hover {
  background-color: #d3fff9;
}

.settings-wheel {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.settings-wheel:hover {
  transform: rotate(5deg);
}

.input-light {
  width: 100%;
  padding: 7px;
  border: 2px solid #97d3df;
  border-radius: 9px;
  background-color: #eafff3;
  color: #277888;
  transition: border 0.2s ease;
}

.input-light:hover {
  border: 2px solid #94eafc;
  box-shadow: none;
  outline: none;
}

.input-light:focus {
  border: 2px solid #56dffa;
  box-shadow: none;
  outline: none;
}

.input-light::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #9dc4cc;
}
.input-light:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #9dc4cc;
}
.input-light::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #9dc4cc;
}
.input-light:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #9dc4cc;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(208, 232, 236, 0);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a7d3db;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #90bdc5;
}

.well-button {
  border: none;
  padding: 8px 18px 8px 18px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  color: #277888;
  transition: background-color 0.05s linear;
  transition: border 0.05s linear;
  cursor: pointer;
  border: 2px dashed #277888;
  font-family: "DMSans";
  text-decoration: none;
  font-size: 18px;
}
.well-button:hover {
  background-color: #eafff3;
  border: 2px dashed #eafff3;
}

@keyframes message-appear {
  0% {
    opacity: 0;
    transform: translateY(3px);
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.message-animation {
  animation: 0.45s message-appear;
}

.bouncing-dot {
  background-color: #277888;
  height: 5px;
  width: 5px;
  border-radius: 50%;
}

.bouncing-dot.dot-1 {
  animation: 1s ease dots-bouncing infinite;
  animation-delay: 0s;
}
.bouncing-dot.dot-2 {
  animation: 1s ease dots-bouncing infinite;
  animation-delay: 0.1s;
}
.bouncing-dot.dot-3 {
  animation: 1s ease dots-bouncing infinite;
  animation-delay: 0.2s;
}

@keyframes dots-bouncing {
  0% {
    opacity: 1;
    transform: translateY(-2px);
  }
  50% {
    opacity: 0.5;
  }
  90% {
    opacity: 0.4;
    transform: translateY(0px);
  }
  100% {
    opacity: 0.6;
    transform: translateY(-1px);
  }
}

@media only screen and (max-width: 899px) {
  .App-header {
    background-image: url("../public/waill-tall.png");
  }
  .primary-button {
    padding: 7px 12px 7px 12px;
    font-size: 15px;
  }
  .secondary-button {
    padding: 5px 10px 5px 10px;
    font-size: 15px;
  }
}

@keyframes appear-enter {
  0% {
    opacity: 0;
    transform: scale(0.9, 0.9);
  }
  50% {
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.animate {
  visibility: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .appear-animation {
    animation: appear-enter ease 1s 1;
  }
}
